import React from "react";
import { Link } from "react-router-dom";

function Typography() {
  return (
    <div className="">
      <h1>This is H1</h1>
      <h2>This is H2</h2>
    </div>
  );
}

export default Typography;
