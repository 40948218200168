import React, { ReactNode } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

interface LayoutProps {
  children: ReactNode;
}

const DocsLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="main-layout flex flex-col md:flex-row">
      <div className="flex-basis-1/5 md:flex-basis-1/4 lg:flex-basis-1/6">
        <Sidebar />
      </div>
      <div className="w-full">
        <Navbar menuType="burger" />
        <div className="content-area p-4">{children}</div>{" "}
        {/* Content will render here */}
      </div>
    </div>
  );
};

export default DocsLayout;
