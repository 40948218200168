import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <aside className="sidebar bg-dark">
      <nav className="sidebar__nav">
        <ul className="sidebar__list">
          <div className="logo">
            {/* <a>
            <h2 className="text-gradient-primary">StriveUi</h2>
          </a> */}
            <Link to="/">
              <h2 className="text-gradient-primary">StriveUi</h2>
            </Link>
          </div>
          <li className="sidebar__item">
            <Link to="/documentation/navbar" className="sidebar__link">
              Navbar
            </Link>
          </li>
          <li className="sidebar__item">
            <Link to="/documentation/buttons" className="sidebar__link">
              Buttons
            </Link>
          </li>
          <li className="sidebar__item">
            <Link to="/documentation/grid" className="sidebar__link">
              Grid Layout
            </Link>
          </li>
          <li className="sidebar__item">
            <Link to="/documentation/typography" className="sidebar__link">
              Typography
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
}

export default Sidebar;
