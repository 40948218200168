import React from "react";

interface NavbarProps {
  menuType?: "arrow" | "burger"; // Add a prop to select the menu type
}

const Navbar: React.FC<NavbarProps> = ({ menuType = "burger" }) => {
  return (
    <nav
      className={`navbar ${
        menuType === "arrow" ? "navbar--arrow-menu" : "navbar--burger-menu"
      }`}
    >
      {/* <div className="logo">
        <a>
          <h1 className="text-gradient-primary">StriveUi</h1>
        </a>
      </div> */}

      <input type="checkbox" id="menu-toggle" />

      {/* Conditionally render burger or arrow icon based on menuType */}
      {menuType === "arrow" ? (
        <label htmlFor="menu-toggle" className="arrow">
          ▼
        </label>
      ) : (
        <label htmlFor="menu-toggle" className="burger">
          <div></div>
          <div></div>
          <div></div>
        </label>
      )}

      <ul className="nav-links">
        <li>
         
          <a>Docs</a>
        </li>
        <li>
          <a>About</a>
        </li>
        <li>
          <a>Contact</a>
        </li>
      </ul>

      {/* Social Icons */}
      <div className="social-icons">
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
