import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import Typography from "../components/Typography";
import GridLayout from "../components/GridLayout";
import Buttons from "../components/Buttons";
import DocsLayout from "../components/DocsLayout";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Landing Page */}
        <Route path="/" element={<LandingPage />} />

        {/* Documentation Pages with DocsLayout */}
        <Route
          path="/documentation"
          element={
            <DocsLayout>
              <Typography /> {/* Default documentation content */}
            </DocsLayout>
          }
        />
        <Route
          path="/documentation/navbar"
          element={
            <DocsLayout>
              <h1>This is the Navbar component</h1>
            </DocsLayout>
          }
        />
        <Route
          path="/documentation/buttons"
          element={
            <DocsLayout>
              <Buttons />
            </DocsLayout>
          }
        />
        <Route
          path="/documentation/grid"
          element={
            <DocsLayout>
              <GridLayout />
            </DocsLayout>
          }
        />
        <Route
          path="/documentation/typography"
          element={
            <DocsLayout>
              <Typography />
            </DocsLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
