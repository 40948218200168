import React from "react";
import { Link } from "react-router-dom";

function GridLayout() {
  return (
    <div className="">
      <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-gap-20">
        <div className="grid-item">Row 1, Col 1</div>
        <div className="grid-item">Row 1, Col 2</div>
        <div className="grid-item">Row 1, Col 3</div>
        <div className="grid-item">Row 2, Col 1</div>
        <div className="grid-item">Row 2, Col 2</div>
        <div className="grid-item">Row 3, Col 1</div>
        <div className="grid-item">Row 3, Col 2</div>
        <div className="grid-item">Row 4, Col 1</div>
        <div className="grid-item">Row 4, Col 2</div>
      </div>

      {/* Additional components or elements */}
    </div>
  );
}

export default GridLayout;
