import React from "react";
import { Link } from "react-router-dom";

function Buttons() {
  return (
    <div className="">
      {/* Primary and Secondary Buttons */}
      <button className="btn btn-primary">Primary Button</button>
      <button className="btn btn-secondary">Secondary Button</button>

      {/* Success Button */}
      <button className="btn btn-info">Danger Button</button>
      <button className="btn btn-success">Success Button</button>

      {/* Danger Button */}
      <button className="btn btn-danger">Danger Button</button>

      {/* Outline Secondary Button */}
      <button className="btn btn-outline-secondary">
        Outline Secondary Button
      </button>
      <button className="btn btn-outline">Outline Button</button>
    </div>
  );
}

export default Buttons;
