import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Navbar from "../components/NavbarLanding";

const LandingPage: React.FC = () => {
  return (
    <Layout>
      <Navbar />
      <Hero />
      <div className="bg-primary h-50">
        <br />
        <h2 className="text-white">Very nice things are coming soon</h2>
      </div>
    </Layout>
  );
};

export default LandingPage;
