import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppRouter from "./router/AppRouter.tsx";
import reportWebVitals from "./reportWebVitals";
import "strive-ui/dist/strive-ui.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const container = document.getElementById("root");
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      {/* Use AppRouter instead of App */}
      <AppRouter />
    </React.StrictMode>
  );
}

reportWebVitals();
