import React from "react";

const Hero: React.FC = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <div>
          <h1>
            Elevate Your UI
            <br />
            pure css, infinite flexibility.
          </h1>
        </div>

        <p>
          Elegant, framework-agnostic components built with pure CSS, ready for
          any project.
        </p>
        <button className="btn btn-primary">Get Started</button>
      </div>
    </section>
  );
};

export default Hero;
